import { HttpInterceptorFn } from "@angular/common/http";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    if (!req.headers.has('bypass-interceptor')) {
        req = req.clone({
            withCredentials: true
        });
    } else {
        req = req.clone({
            headers: req.headers.delete('bypass-interceptor')
        });
    }

    return next(req);
}