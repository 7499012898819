import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';
import moment from 'moment';
import validator from 'validator';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private ToastController: ToastController) {}

    public createToast = async (message: string) => {
        const toast = await this.ToastController.create({
            message: message,
            duration: 3000,
        });

        await toast.present();
    };

    public validateURL = (control: AbstractControl): { [key: string]: any } | null => {
        const url = control.value;

        if (url == '' || validator.isURL(url, { protocols: [ 'http', 'https' ], require_protocol: true })) {
            return null;
        }

        return { 'invalidUrl': true }
    }

    public noPeriodsValidator = (control: AbstractControl): { [key: string]: any } | null => {
        const inputText = control.value;

        if (inputText && inputText.includes('.')) return { 'noPeriods': true };

        return null;
    }

    public getFormInputError = (input: any) => {
        if (input && input.invalid) {
            if (input.errors?.['required']) {
                return 'This is a required field';
            }

            if (input.errors?.['email']) {
                return 'Please enter a valid email address';
            }

            if (input.errors?.['minlength']) {
                return `Please enter at least ${input.errors.minlength.requiredLength} characters`;
            }

            if (input.errors?.['min']) {
                return `Please enter an amount greater than or equal to ${input.errors.min.min}`;
            }

            if (input.errors?.['invalidUrl']) {
                return `Please enter a valid URL`;
            }

            if (input.errors?.['noPeriods']) {
                return `Periods are not allowed`;
            }
          
            if (input.errors?.['invalidDateRange']) {
                return 'Invalid Start Date/End Date';
            }
          
            if (input.errors?.['duplicateCaseNumber']) {
                return `Duplicate Case # is not allowed`;
            }
        }

        return '';
    };

    public getVersion() {
        return packageJson.version;
    }

    public getEnvironment() {
        return environment.env;
    }
    
    public computeDateAfterMonths = (startDate: Date, months: number): Date => {
        const futureDate = moment(startDate).add(months, 'months').toDate();
        return futureDate;
    };
    
    public computeYears = (startDate: Date, endDate: Date): number => {
        const newStartDate = moment(startDate);
        const newEndDate = moment(endDate);
    
        const startYear = newStartDate.year();
        const endYear = newEndDate.year();
        
        let yearsDifference = endYear - startYear;
    
        return yearsDifference;
    };

    public computeTenure = (startDate: any) => {
        if (!startDate) {
            return;
        }

        const currentDate = moment().startOf('day');
        const convertedStartDate = moment(startDate).startOf('day');
        const tenure = moment.duration(currentDate.diff(convertedStartDate));

        return `${tenure.years()}y ${tenure.months()}m ${tenure.days()}d`;
    };

    public dateRangeValidator(control: AbstractControl): ValidationErrors | null {
        const formGroup = control as FormGroup;
        const start_date = formGroup.get('start_date')?.value;
        const end_date = formGroup.get('end_date')?.value;
        const today = moment().startOf('day');

        if (!start_date || !end_date) {
          return null;
        }

        if (moment(start_date).isBefore(today)) {
            const startDateControl = formGroup.get('start_date');
            if (startDateControl) {
                startDateControl.setErrors({ invalidDateRange: true });
            }
            return { invalidDateRange: true };
        }

        if ((end_date && moment(end_date).isBefore(today)) ||
          (end_date && moment(end_date).isBefore(start_date)) ||
          (start_date && end_date && moment(start_date).isSame(end_date))
        ) {
            const endDateControl = formGroup.get('end_date');
            if (endDateControl) {
                endDateControl.setErrors({ invalidDateRange: true });
            }
            return { invalidDateRange: true };
        }
    
        return null;
    }
    
    public duplicateCaseNumberValidator(formArray: AbstractControl): ValidationErrors | null {
        if (!(formArray instanceof FormArray)) return null;
      
        const caseNumbers = formArray.controls
          .map(control => (control as FormGroup).get('case_number')?.value)
          .filter(value => value?.trim());

        formArray.controls.forEach(control => {
            const caseNumberControl = (control as FormGroup).get('case_number');
            if (caseNumberControl) {
                caseNumberControl.setErrors(null);
            }
        });
      
        const duplicates = caseNumbers.filter((value, index, self) => self.indexOf(value) !== index);
        const seenOnce = new Set<string>();

        if (duplicates.length > 0) {
            caseNumbers.forEach((value, index) => {
                if (duplicates.includes(value) && !seenOnce.has(value)) {
                    seenOnce.add(value);
                } else if (duplicates.includes(value) && seenOnce.has(value)) {
                    const control = formArray.at(index) as FormGroup;
                    const caseNumberControl = control.get('case_number');
                    if (caseNumberControl) {
                        caseNumberControl.setErrors({ 'duplicateCaseNumber': true });
                    }
                }
            });
    
            return { 'duplicateCaseNumber': true };
        }
      
        return null;
    }
}
